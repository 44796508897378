import Service from "../Service";

const resource = "billing/";

export default {
    save(bil, requestID) {
        return Service.post(resource + "save", bil, {
            params: { requestID }
        })
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

}