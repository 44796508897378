<template>
	<div>
		<v-container>
			<s-crud
				:config="configBilling"
				:filter="filter"
				@dblClick="dblClick($event)"
				@rowSelected="rowSelected($event)"
				ref="crudBilling"
				sortable
			>
				<template v-slot:BilStatusAdvanceName="{row}">
					<v-chip x-small :color="row.BilStatusAdvanceColor">
						{{row.BilStatusAdvanceName}}
					</v-chip>
				</template>

				<template v-slot:BilStatusResidueName="{ row }">
					<v-chip x-small :color="row.BilStatusResidueColor">
						{{row.BilStatusResidueName}}
					</v-chip>
				</template>
			</s-crud>

			<v-dialog
				v-model="dialogBilling"
				fullscreen
			>
				<v-card>
					<s-toolbar
						label="Pagos"
						color="light-blue darken-3"
						dark
						close
						@close="dialogBilling = false"
					>
					</s-toolbar>
					<payments :Billing="Billing" @closeModal="closeModal"></payments>
				</v-card>
			</v-dialog>

		</v-container>
	</div>
</template>

<script>
	import _sSleBilling from "@/services/Sale/SleBillingService.js";
	import Payments from './Payments.vue';
	export default {
  		components: { Payments },
		data() {
			return {
				Billing: [],
				filter: {},
				configBilling: {
					model: {
						BilOv: "ID",
						BilProjectedAdvanceDate: "date",
						BilRealAdvanceDate: "date",
						BilProjectedResidueDate: "date",
						BilRealResidueDate: "date",
						BilStatusAdvanceName: "int",
						BilStatusAdvanceColor: "string",
						BilStatusResidueName: "string",
						BilStatusResidueColor: "string",
						
					},
					service: _sSleBilling,
					headers: [
						{text: "OV", value: "BilOv", width:"100"},
						/* {text: "Vía", value: "TypeBoarding", width:"100"}, */
						{text: "Cliente", value: "BilCustomer", width:"100"},
						{text: "Contenedor", value: "BilContainer", width:"100"},
						{text: "Linea Naviera", value: "BilShipping", width:"100"},
						{text: "Nave", value: "BilShip", width:"100"},
						{text: "PROY_FECHA_ADEL", value: "BilProjectedAdvanceDate", width:"150"},
						{text: "REAL_FECHA_ADEL", value: "BilRealAdvanceDate", width:"150"},
						{text: "MONTO_ADEL", value: "BilProjectedAdvanceAmount", width:"150"},
						{text: "Monto_Adel_Real", value: "BilRealAdvanceAmount", width:"150"},
						{text: "PROY_FECHA_SALDO", value: "BilProjectedResidueDate", width:"150"},
						{text: "REAL_FECHA_SALDO", value: "BilRealResidueDate", width:"150"},
						{text: "MONTO_SALDO", value: "BilProjectedResidueAmount", width:"150"},
						{text: "MONTO_SALDO_Real", value: "BilRealResidueAmount", width:"150"},
						{text: "Monto_Total", value: "BilTotal", width:"150"},
						{text: "STATUS_ADEL", value: "BilStatusAdvanceName", width:"150"},
						{text: "STATUS_SALDO", value: "BilStatusResidueName", width:"150"},
					]
				},
				dialogBilling: false
			}
		},

		methods: {
			dblClick(item) {
				this.dialogBilling = true;
			},

			closeModal()
			{
				this.dialogBilling = false;
				this.$refs.crudBilling.refresh();
			},

			rowSelected(rows){
				if(rows[0] != null){
					this.Billing = rows[0];
				}
				
			}
		},
	}
</script>