<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" lg="6" md="12" sm="12">
					<v-card>
						<s-toolbar 
							label="Saldo"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-date 
										label="Fecha Saldo Proy"
										v-model="Billing.BilProjectedResidueDate"
										disabled
									>
									</s-date>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-text 
										decimal 
										label="Monto Saldo Proy"
										v-model="Billing.BilProjectedResidueAmount"
										disabled
									>
									</s-text>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-date 
										:disabled="Billing.BilStatus == 2"
										label="Fecha Saldo Real"
										v-model="Billing.BilRealResidueDate"
									>

									</s-date>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-text 
										:disabled="Billing.BilStatus == 2"
										decimal 
										label="Monto Saldo Real"
										v-model="Billing.BilRealResidueAmount"
									>
									</s-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6" md="12" sm="12">
					<v-card>
						<s-toolbar 
							label="Adelanto"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-date 
										label="Fecha Adel Proy"
										v-model="Billing.BilProjectedAdvanceDate"
										disabled
									>
									</s-date>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-text 
										decimal 
										label="Monto Adel Proy"
										v-model="Billing.BilProjectedAdvanceAmount"
										disabled
									>
									</s-text>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-date 
										:disabled="Billing.BilStatus == 2"
										label="Fecha Adel Real"
										v-model="Billing.BilRealAdvanceDate"
									>
									</s-date>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-text 
										:disabled="Billing.BilStatus == 2"
										decimal 
										label="Monto Adel Real"
										v-model="Billing.BilRealAdvanceAmount"
									>
									</s-text>
								</v-col>
							</v-row>
							
						</v-container>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" lg="6" md="12" sm="12">
					<v-card>
						<s-toolbar 
							label="Liquidación"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-date 
										:disabled="Billing.BilStatus == 2"
										label="Fecha Liquidación"
										v-model="Billing.BilSettlementDate"
									>
									</s-date>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-text 
										:disabled="Billing.BilStatus == 2"
										decimal 
										label="Monto Liquidación"
										v-model="Billing.BilSettlementAmount"
									>
									</s-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6" md="12" sm="12">
					<v-card>
						<s-toolbar 
							label="Otros Ingresos"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-date 
										:disabled="Billing.BilStatus == 2"
										label="Otro Ingreso"
										v-model="Billing.BillOtherIncomeDate"
									>
									</s-date>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-text 
										:disabled="Billing.BilStatus == 2"
										decimal 
										label="Monto Ingreso"
										v-model="Billing.BilOtherIncomeAmount"
									>
									</s-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" lg="6" md="12" sm="12">
					<v-card>
						<s-toolbar 
							label="Pre Campaña"
							color="#E0F7F2"
						></s-toolbar>
						<v-container>
							<v-row>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-date 
										:disabled="Billing.BilStatus == 2"
										label="Fecha Pago"
										v-model="Billing.BilPreCampaignDate"
									>
									</s-date>
								</v-col>
								<v-col cols="3" lg="6" md="6" sm="12">
									<s-text 
										:disabled="Billing.BilStatus == 2"
										decimal 
										label="Monto Pago"
										v-model="Billing.BilPreCampaignAmount"
									>
									</s-text>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6" md="12" sm="12">
					<s-select-definition
						:def="1314"
						label="Estado"
						v-model="Billing.BilStatus"
					></s-select-definition>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" lg="12" md="12" sm="12">
					<v-btn
						color="primary"
						block
						@click="save"
					>
						GUARDAR
					</v-btn>
				</v-col>	
			</v-row>
			
		</v-container>
	</div>
</template>

<script>
	import _sSleBillingService from "@/services/Sale/SleBillingService"
	export default {
		props: {
			Billing: {
				type: Object,
				default: null
			},
		},

		methods: {
			save() {
				this.Billing.UsrUpdateID = this.$fun.getUserID();
				_sSleBillingService.save(this.Billing, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Guardado correctamente", "success");
						this.$emit('closeModal');
					}
				})
			}
		},

	}
</script>